<template>
  <div>
    <h4>{{ $t('PUBLIC.INQUIRY.OTHER_SERVICES') }}</h4>

    <v-row>
      <v-col md="6">
      
        <v-checkbox value="SENDOUT" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.SENDOUT')"></v-checkbox>
        <v-checkbox value="TODO" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.TODO')"></v-checkbox>
        <v-checkbox value="EVENTS" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.EVENTS')"></v-checkbox>
        <v-checkbox value="FORTNOX" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.FORTNOX')"></v-checkbox>
        <v-checkbox value="SPAR" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.SPAR')"></v-checkbox>
        <v-checkbox value="LADOK" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.LADOK')"></v-checkbox>
        <v-checkbox value="REGISTRATIONS" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.REGISTRATIONS')"></v-checkbox>
        <v-checkbox value="FILEUPLOADS" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.FILEUPLOADS')"></v-checkbox>
        <v-checkbox value="FILEMANAGEMENT" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.FILEMANAGEMENT')"></v-checkbox>
        <v-checkbox value="COUPONS" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.COUPONS')"></v-checkbox>
        <v-checkbox value="GRANT" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.GRANT')"></v-checkbox>
        <v-checkbox value="DEACTIVATION" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.DEACTIVATION')"></v-checkbox>
        <v-checkbox value="HISTORY" v-model="form.services" :label="$t('PUBLIC.INQUIRY.SERVICES.HISTORY')"></v-checkbox>
        
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <h4>{{ $t('PUBLIC.INQUIRY.OTHER_TEXT') }}</h4>
        <v-textarea
          variant="outlined"
          v-model="form.text"
        ></v-textarea>
      </v-col>
    </v-row>


    <p v-if="show_error" class="mt-8" style="color: #fd397a;">{{ $t('PUBLIC.INQUIRY.MISSING_FIELDS') }}</p>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
      <b-button
        class="mt-4 mr-4"
        variant="outline-secondary"
        @click.prevent="on_back"
      >
        {{ $t('COMMON.BACK') }}
      </b-button>
      <b-button
        class="mt-4"
        variant="primary"
        :type="type"
        :disabled="disabled || spinning"
        @click.prevent="on_click"
      >
        <memlist-spinner v-if="spinning" />
        {{ $t('PUBLIC.INQUIRY.SEND_INQUIRY') }}
      </b-button>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'InquiryCompanyFormStep3',
  mixins: [ toasts ],

  data() {
    return {
      form: {
        services: []
      },
      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  },
  mounted() {
    
  },

  computed: {
    
  },


  watch: {
    
  },
  methods: {
    validated(field_name, value, valid) {
      this.form[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    on_back() {
      this.$emit('back');
    },

    async on_click() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(()=>{
        window.scrollTo(0,0);
        
        if (this.is_form_valid) {
          this.spinning = false;

          this.$emit('next', this.form, 2);
        }
        else {
          this.show_error = true; 

          setTimeout(()=>{
            this.spinning = false;
          }, 2000);
        }
      });
    },

  }
};
</script>
